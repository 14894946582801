<template>
  <div class="loadingbg" v-loading="true" element-loading-text="同步数据中..."
    element-loading-background="rgba(255, 255, 255, 0)">
    <img src="../../assets/lg.png" alt="" />
  </div>
</template>

<script>
export default {
  mounted() {
    let opener = window.opener;
    // 只有 http://127.0.0.1:5500 网页打开的页面，才可以收到来自 B 页面的信息
    opener.postMessage('2', 'https://mashangqian.yoojet.com');
  }
};
</script>

<style>
.loadingbg {
  background-image: url("../../assets/mobileLoginBG.png");
  height: 100%;
  background-position-y: -150px;
  box-sizing: border-box;
  padding-top: 10vh;
}

.loadingbg img {
  display: block;
  width: 80vw;
  margin: 0 auto;
}
</style>